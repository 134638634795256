.header {
   color: white;
   font-size: 0.8rem;
   font-weight: 900;
   height: 96px;
   margin: 0 auto 0;
   /* max-width: 1920px; */
   padding: 0;
   position: fixed;
   text-align: center;
   top: 0;
   width: 100%;
   z-index: 70;
}

.navbar-left {
   position: absolute;
   left: 25px;
   top: 0;
   z-index: 76;
}

.navbar-center {
   left: 50%;
   position: absolute;
   top: -5px;
   transform: translate(-50%);
   z-index: 74;
}
.navbar-right {
   position: absolute;
   right: 0;
   top: 0;
   z-index: 76;
}

/* -------------------- */
/* images behind nav */
/* -------------------- */

.woodenheader {
   background-position: center center;
   background-size: contain;
   box-shadow: 0 0 50px black, 0 0 10px black;
   height: 85px;
   position: absolute;
   top: 0;
   width: 100%;
   z-index: 70;
}

.metalsheet {
   max-height: 130px;
   position: absolute;
   top: 0;
   z-index: 72;

}
.metalsheetleft {
   left: 0;
}  
.metalsheetright {
   right: 0;
}

/* -------------------- */
/* left nav */
/* -------------------- */

.bookmarkBlock {
   margin-top: 0 !important;
}

.bookmark {
   margin-top: -20px;
   max-width: 90px;
   transition: all ease-in-out 0.25s;
}

.bookmark-slide {
   margin-top: 0px;
   transition: all ease-in-out 0.25s;
}

.bookmark-slide:hover, .bookmark-slide:focus {
   cursor: grab;
   margin-top: 15px !important;
   transition: all ease-in-out 0.25s;
}

/* -------------------- */
/* center nav */
/* -------------------- */

.header-top {
   position: fixed;
   z-index: 80;
}

.header-bottom {
   background-color: #40382F;
   position: absolute;
   z-index: 80;
}

.HeaderCenterTop {
   max-height: 135px;
}

.HeaderCenterBottom {
   margin-top: -10px;
}

/* -------------------- */
/* right nav */
/* -------------------- */

.header-pipes {
   max-height: 155px;
}

.header-clock {
   margin: -4px -58px 0 0;
   width: 170px;
}

.header-pipe {
   max-height: 121px;
}