.home {
   align-items: center;
   background: linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(../images/backgrounds/Drenwal-World-Map-v7_3-frameless-blur.jpg) no-repeat;
   background-position: center center;
   background-size: cover;
   display: flex;
   flex-direction: column;
   height: 100vh;
   justify-content: center;
   position: relative;
   width: 100vw;
}
  
.home__container {
   background-color: #dcdcdc;
   display: flex;
   flex-direction: column;
   padding: 30px;
   text-align: center;
   z-index: 20;
}
  
.home__textBox {
   font-size: 18px;
   margin-bottom: 10px;
   padding: 10px;
}
  
.play-dead {
   pointer-events: none;
}

.stay-boy {
   position: absolute;
}

.r4k img {
   left: 50%;
   position: relative;
   transform: translate(-50%);
   width: 400px;
   z-index: 12;
}

.logo-d4 {
   height: 80vh;
   max-height: 600px;
   z-index: 10;
}

  @media (prefers-reduced-motion: no-preference) {
    .spin-1 {
      animation: spin-left infinite 2s linear;
    }
    .spin-2 {
      animation: spin-right infinite 10s linear;
    }
  }

  @keyframes spin-right {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes spin-left {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }