@font-face {
   font-family: "Estonia";
   src: url("../fonts/google/Estonia-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Germania";
   src: url("../fonts/google/GermaniaOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "HomemadeApple";
   src: url("../fonts/google/HomemadeApple-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "KolkerBrush";
   src: url("../fonts/google/KolkerBrush-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Pirata";
   src: url("../fonts/google/PirataOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Quintessential";
   src: url("../fonts/google/Quintessential-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

.loginFormTitle {
   font-family: "Pirata";
   font-size: 38px;
   margin-bottom: 10px;
}

.loginFormText {
   font-family: "Quintessential";
   font-size: 18px;
   font-weight: 700;
   line-height: 30px;
   text-align: left;
}

input, input:placeholder-shown {
   color: #000 !important;
}

.login {
   height: 100vh;
   width: 100vw;
   display: flex;
   align-items: center;
   justify-content: center;
}

.login__container {
   background-repeat: no-repeat;
   background-size: contain;
   display: flex;
   flex-direction: column;
   height: 700px;
   padding: 70px;
   text-align: center; 
   width: 600px;
}

.login__textBox {
   font-size: 18px;
   margin-bottom: 10px;
   padding: 10px;
}

.login__btn {
   background-color: rgba(0, 0, 0, 0.5);
   border: 1px solid black;
   border-radius: 7px;
   color: white;
   font-size: 18px;
   margin: 0 auto;;
   padding: 10px 20px;
   width: max-content;
}
.login__btn:hover {
   background-color: rgba(0, 0, 0, 0.75);
}

.login__google {
   background-color: rgba(66, 133, 244, .5);
   border: 1px solid black;
}
.login__google:hover {
   background-color: rgba(66, 133, 244, .8);
   border: 1px solid white;
}


.login div {
   margin-top: 7px;
}
