@font-face {
   font-family: "Estonia";
   src: url("../fonts/google/Estonia-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Germania";
   src: url("../fonts/google/GermaniaOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "HomemadeApple";
   src: url("../fonts/google/HomemadeApple-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "KolkerBrush";
   src: url("../fonts/google/KolkerBrush-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Pirata";
   src: url("../fonts/google/PirataOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Quintessential";
   src: url("../fonts/google/Quintessential-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

/* ____________________________ */
/* Journal modals */

.center {
   margin: auto; 
   text-align: center;
}

.modal-backdrop.show {
   opacity: .75 !important;
}

.modal-dialog {
   margin: 0px auto !important; 
   max-width: 90% !important; 
}

.modal-dialog-centered {
   min-height: inherit !important;
}

.modal-content {
   background-color:rgba(0,0,0,0) !important;
   border: 0 !important;
   height: 90%;
   margin: 20px auto 0;
   max-width: 1600px;
}
.modal-body {
   margin: auto;
   padding: 0px !important;
   width: 100%;
}

.react-tabs {
   height: 100%;
}

.react-tabs__tab-panel--selected {
   height: 92%;
   padding: 20px;
}


/* ____________________________ */
/* sidebar */

.offcanvas-start {
   border-right: 0px !important;
   box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.9);
}

.offcanvas-title {
   font-family: "Pirata";
   font-size: 24px !important;
}

.offcanvas-body {
   padding: 0px !important;
}

/* ____________________________ */
/* New CSS for modals */

.main-modal {
   max-height: 100vh;
}

.masterModal {
   
}

.modalUpper {
   height: 100px;
   padding: 10px;
}

.modalTitle {
   margin-bottom: -50px;
   max-width: 100%;
}

.modalDowner {
   border-radius: 5px;
   box-shadow: 0px 10px 40px 7px rgba(0, 0, 0, 1);
   height: 600px;
   padding: 10px;
}

.modalStitch {
   border: 2px dashed brown;
   height: 100%;
   width: 100%;
}

.modalGuts {
   height: inherit;
   padding: 20px;
   width: inherit;
}

.modalIframeOverrides1 {
   height: 100% !important;
   width: 100% !important;
}

.modalIframeOverrides2 {
   height: 0;
   padding-bottom: 60vh; 
   padding-top: 0; 
   position: relative; 
}

.modalIframeOverrides3 {
   position: absolute; 
   top: 0; 
   left: 0; 
   width: 100%; 
   height: 100%;
}