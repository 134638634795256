@font-face {
   font-family: "Estonia";
   src: url("../fonts/google/Estonia-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Germania";
   src: url("../fonts/google/GermaniaOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "HomemadeApple";
   src: url("../fonts/google/HomemadeApple-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "KolkerBrush";
   src: url("../fonts/google/KolkerBrush-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Pirata";
   src: url("../fonts/google/PirataOne-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: "Quintessential";
   src: url("../fonts/google/Quintessential-Regular.ttf");
   font-weight: 400;
   font-style: normal;
}

.journal, .maps {
   height: inherit;
   width: inherit;
}

.tabGuts {
   height: 100%;
   transition: all ease-in-out 0.5s;
   width: 100%;
}

.tabGuts p {
   font-family: "HomemadeApple";
   font-size: 18px;
}

.react-tabs__tab {
   background: rgba(0, 0, 0, 0.25);
   border-radius: 15px 15px 0 0;
   color: white;
   font-family: "Germania";
   font-size: 18px;
   font-weight: 100;
   margin-right: 10px;
   padding: 10px 20px;
   transition: all ease-in-out 0.5s;
}

.react-tabs__tab:hover {
   background: rgba(0, 0, 0, 0.5);
   font-weight: 900;
}

.react-tabs__tab-list {
   border-bottom: 0px;
   margin: 0;
}

.react-tabs__tab--selected {
   background: #000;
   border-color: #aaa;
   border-radius: 5px 5px px 0 0;
   color: whitesmoke;
   font-weight: 900;
}

.react-tabs__tab--selected:hover {
   background: #000 !important;
}

.react-tabs__tab-panel {
   transition: all ease-in-out 0.5s;
}

.react-tabs__tab-panel--selected {
   background-color: rgba(250, 235, 215, 0.5);
   padding: 0px;
}

.team-journal {
   height: 100%;
   width: 100%;
}