.CharacterBox {
    /* background: url(../images/backgrounds/book-paper-2560.jpg) repeat; */
    background-color: rgba(0, 0, 0, 0.1);
    border: dashed 1px black;
    border-radius: 5px;
    min-height: 550px;
    overflow: scroll;
    padding: 10px;
    width:300px;
}
#Equipment, #Weapons {
    flex-grow: 1; /* This will make the div take up the remaining space */
}
#CharacterLeft {
    position: absolute;
    left: 15px;
}
#CharacterRight {
    position: absolute;
    right: 15px;
}

.card-holder {
    float: left;
    margin: 5px;
}
.card-holder-vertical {
    height: 120px;
    width: 80px;
}
.card-holder-horizontal {
    height: 80px;
    width: 120px;
}

.weaponx {
    background-color: white;
    border-radius: 10px;
    border: solid 0.5px black;
    box-sizing: border-box;
    height: 130px;
    width: 182px;
    .weaponx-card {
        margin: 5px;
    }
}