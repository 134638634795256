.dashboard {
   align-items: center;
   /* background: linear-gradient(#67A5BF, #EDFCFF); */
   /* box-shadow: 0px 10px 40px 7px rgba(0, 0, 0, 1); */
   background-size: cover;
   display: flex;
   height: 100vh;
   justify-content: center;
   margin: auto;
   /* max-width: 1920px; */
   width: 100%;
}

.dashboard__container {
   box-shadow: 0px 10px 40px rgba(0, 0, 0, 1);
   display: flex;
   flex-direction: column;
   height: 100%;
   padding: 85px 50px 60px;
   text-align: center;
   width: 100%;
}

.dashmap {
   border: 0;
   border-radius: 5px;
   box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.5);
   height: 100%;
   width: 100%;
}

.temp_hitList {
  font-size: 2.5em;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
}

.temp_list {
  margin:auto; 
  max-width:550px; 
  padding:20px; 
}

.temp_list > figure {
  text-align: left;
  width: 100%;
}

.temp_subheader {
  font-size: 1.5em;
  text-align: left;
  text-transform: uppercase;
}

.temp_200 {
  font-weight: 200;
}

ol li {
  text-indent: -15px;
}

ol, ul {
  padding-left: 3rem;
}