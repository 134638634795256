@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
.App {
  font-family: sans-serif;
}

.clock {
  width: 90px;
  height: 90px;
  background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  left: 40px;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 1);
  font-size: 12px;
  color: #444;
  text-align: center;
  z-index: 90;
}

.clock::after {
  background: #000;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* border: 2px solid #fff; */
}

.hour_hand {
  position: absolute;
  box-shadow: -2px -2px 7px rgba(0, 0, 0, 1);
  width: 3px;
  height: 20px;
  background: #fff;
  top: 24px;
  left: 44px;
  transform-origin: bottom;
}

.min_hand {
  position: absolute;
  box-shadow: -2px -2px 7px rgba(0, 0, 0, 1);
  width: 2px;
  height: 30px;
  background: #999;
  top: 15px;
  left: 44px;
  transform-origin: bottom;
}

.sec_hand {
  position: absolute;
  box-shadow: -2px -2px 7px rgba(0, 0, 0, 1);
  width: 2px;
  height: 25px;
  background: red;
  top: 20px;
  left: 44px;
  transform-origin: bottom;
}

.clock span {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

.twelve {
  top: 7px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.one {
  top: 10%;
  right: 26%;
}

.eleven {
  top: 10%;
  left: 26%;
}

.two {
  top: 25%;
  right: 10%;
}

.three {
  right: 10px;
  top: 46%;
}

.four {
  right: 30px;
  top: 67%;
}

.five {
  right: 78px;
  top: 80%;
}

.six {
    bottom: -8px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.seven {
  left: 80px;
  top: 82%;
}

.eight {
  left: 30px;
  top: 67%;
}

.nine {
  left: 10px;
  top: 46%;
}

.ten {
  top: 25%;
  left: 10%;
}
