.footer{
   background-position: top center;
   background-repeat: no-repeat;
   background-size: cover;
   bottom: 0;
   color: white;
   font-size: 0.65rem;
   font-weight: 900;
   height: 100px;
   margin: 1rem auto 0;
   max-width: 1600px;
   position: fixed;
   text-align: center;
   width: 100%;
}

.FooterPanel {
   z-index: inherit;
}

.footerLogout {
   bottom: 9px;
   cursor: help;
   height: 40px;
   margin-bottom: 5px;
   position: absolute;
   right: 120px;
}

/* .footerLogout:hover {
   cursor: help;
} */

.footerBackground {
   bottom: 0;
   left: 50%;
   position: fixed;
   transform: translate(-50%);
   width: 100%;
}

.footer-button {
   background-color: rgb(79, 110, 131);
   font-size: 1em;
   padding: 5px 15px;
}

.footer-button:hover {
   background-color: rgb(62, 91, 110);
   font-size: 1em;
   padding: 5px 15px;
}