.app {
   background-position: center center;
   background-size: cover;
}

.brown3 {
   background-color: #736555;
}
.brown4 {
   background-color: #40382F;
}
